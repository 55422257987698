/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

@font-face {
  font-family: "SVNGothamBook";
  src: url("/assets/fonts/SVN-Gotham Bold.otf");
}

@font-face {
  font-family: "GothamBook";
  src: url("/assets/fonts/GothamBook.otf");
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.remove-arrow {
  -moz-appearance: textfield;
}

.bg-wrapper {
  background: url('/assets/images/bg.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  position: fixed;
  z-index: -1;
}

.cdk-overlay-pane {
  .ng-trigger {
    padding-top: 0px;
  }
}

.required::after {
  content: "*";
  color: red;
  margin-left: 3px;
}